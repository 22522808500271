<template>
  <form @submit.prevent="store.SUBMIT_FORM(state.usuario)">
    <DxForm
      ref="form"
      :form-data="state.usuario"
      :show-colon-after-label="true"
      :col-count-by-screen="{ lg: 2, md: 2, sm: 1, xs: 1 }"
    >
      <DxSimpleItem data-field="id" :visible="false" />

      <DxSimpleItem
        data-field="nome"
        :editor-options="{ readOnly: state.usuario.isReadonly }"
      >
        <DxRequiredRule />
      </DxSimpleItem>

      <DxSimpleItem
        data-field="email"
        :editor-options="{ readOnly: state.usuario.isReadonly }"
      >
        <DxRequiredRule />
        <DxEmailRule />
      </DxSimpleItem>

      <DxSimpleItem
        data-field="senha"
        :editor-options="{
          mode: 'password',
          readOnly: state.usuario.isReadonly,
        }"
        :visible="isNew"
      >
        <DxRequiredRule />
      </DxSimpleItem>

      <DxSimpleItem
        data-field="confirmSenha"
        :label="{ text: 'Confirmar Senha' }"
        :editor-options="{
          mode: 'password',
          readOnly: state.usuario.isReadonly,
        }"
        :visible="isNew"
      >
        <DxRequiredRule />
        <DxCompareRule :comparison-target="ConfirmPassword" />
      </DxSimpleItem>

      <DxSimpleItem
        data-field="tipoUsuario"
        editor-type="dxSelectBox"
        :label="{ text: 'Tipo Usuário' }"
        :editor-options="{
          items: state.tipoUsuario,
          displayExpr: 'display',
          valueExpr: 'id',
          readOnly: state.usuario.isReadonly,
        }"
      >
        <DxRequiredRule />
      </DxSimpleItem>

      <DxSimpleItem
        data-field="contabilistaID"
        editor-type="dxSelectBox"
        :label="{ text: 'Contabilista' }"
        :col-count="2"
        :col-span="2"
        :editor-options="{
          items: state.contabilistas,
          displayExpr: 'nomeFantasia',
          valueExpr: 'id',
          readOnly: state.usuario.isReadonly,
          disabled: !showSelectBoxContabilista
        }"
      >
        <DxRequiredRule />
      </DxSimpleItem>

      <DxSimpleItem data-field="ativo" :label="{ text: 'Ativo?' }" />

      <DxSimpleItem item-type="empty" />
      <DxSimpleItem item-type="empty" />
      <DxSimpleItem item-type="group" :col-count="2" :col-span="2">
        <DxButtonItem :button-options="saveButtonProperties"></DxButtonItem>
        <DxButtonItem :button-options="cancelButtonProperties"></DxButtonItem>
      </DxSimpleItem>
    </DxForm>
  </form>
</template>

<script lang="ts" src="./index.ts"></script>