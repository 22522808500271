import { tipoUsuario } from './../../models/ITipoUsuarioEnum';
import { usuarioStore } from './../../store/modules/usuarios/index';
import { IUsuarioRequest } from './../../models/IUsuarioRequest';
import { computed, defineComponent, onMounted, reactive, ref} from "vue";
import eventBus from '../../helpers/event-bus';
import { DxForm, DxSimpleItem, DxRequiredRule, DxCompareRule, DxEmailRule, DxButtonItem, DxButtonOptions } from 'devextreme-vue/ui/form'
import ContabilistaService from "../../services/contabilistas/ContabilistaService";
import { useToast } from 'vue-toastification';

export default defineComponent({
  name: 'FormUsuario',
  props: {
    propsComponent: {
      required: true,
      type: Object as () => IUsuarioRequest
    }
  },
  setup(props: any) {
    onMounted(() => {
      GetContabilistas()
    })
    
    const toast = useToast()
    const form = ref()
    const popup = eventBus.usePopup()
    const store = usuarioStore()
    const state = reactive({
      contabilistas: {} as Record<string, string>,
      usuario: props.propsComponent,
      tipoUsuario: tipoUsuario,
    })

    const showSelectBoxContabilista = computed(() => {
      return state.usuario.tipoUsuario === 2 ? true : false
    })

    const isNew = computed(() => {
      return state.usuario.id !== 0 ? false : true
    })

    const saveButtonProperties = {
      text: 'Salvar',
      type: 'default',
      stylingMode: 'text',
      icon: 'mdi mdi-content-save',
      useSubmitBehavior: true,
    }

    const cancelButtonProperties = {
      text: 'Cancelar',
      type: 'danger',
      stylingMode: 'text',
      onClick: () => popup.close()
    }

    function ConfirmPassword(){
      return state.usuario.senha
    }

    async function GetContabilistas() {
      try {
        const response = await ContabilistaService.GetContabilistas();
        if (response.status === 200) state.contabilistas = response.data
      } catch (error: any) {
        toast.error(error.response.data)
      }
    }

    return {
      form,
      state,
      store,
      saveButtonProperties,
      cancelButtonProperties,
      showSelectBoxContabilista,
      isNew,
      ConfirmPassword,
    }
  },
  components: {
    DxForm,
    DxSimpleItem,
    DxRequiredRule,
    DxCompareRule,
    DxEmailRule,
    DxButtonItem,
    DxButtonOptions
  }
})